import { useState, useEffect } from 'react';

import './Vino.scss';

const Vino = () => {
    const [prices, setPrices] = useState([{"name":"Loading...","description":"Loading...","price":"Loading...","imgpath":"Loading..."}]);
  const [isFetched, setIsFetched] = useState(false);
  useEffect(() => {
    async function fetchData() {
        await fetch('data/prices.json')
        .then(response => response.json())
      .then(data => {
        setPrices(data);
        setIsFetched(true);
      })
      .catch(error => {
        console.error(error); // Handle errors
      });
    }
    fetchData();
  }, []);
    return (
        <div className="Vino">
            <div className="row-st">
                <div className='row-st__title'>

                <h3 className='big-text tb'>Vini sfusi</h3>
                <p className='normal-text'>Vini sfusi di alta qualità dalle cantine friulane. Dal tradizionale vino rosso al vino bianco fino ad una vasta varietà.</p>
                {
                isFetched ? <p>Prezzi da € {prices?.vino.price}</p>
                : null
                }
                </div>
                <img alt='vini sfusi immagine' src={require('../../media/gallery/vini-sfusi.JPG')}></img>
            </div>
            <div className="row-nd">
                <div className='row-nd__title'>

                <h3 className='big-text tb'>E in bottiglia</h3>
                <p className='normal-text'>Vini in bottiglia dalle cantine Colli Euganei e Monviert.</p>
                </div>
                <img alt='vini bottiglia immagine' src={require('../../media/gallery/vini-bottiglia.JPG')}></img>
            </div>
        </div>
    );
}

export default Vino;