import { Button } from 'react-bootstrap';
import './Hero.scss';

const Hero = ({onClick}) => {
    return (
        <div className="hero">
    <div className="hero__content scroll">
      <p className="intro-text">Legna, pellet e vino a Opicina</p>
      <h1 className="big-text">PIOVE di Goldin Silvio</h1>
      {/* TODO: add automatic scroll */}
      <Button size='lg' onClick={onClick}>Scopri di più</Button>
    </div>
  </div>
    )
}

export default Hero;