import { useNavigate, Routes, Route } from 'react-router-dom';

/* Components */
import Layout from './components/layout/Layout';

/* Pages */
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
}

export default App;
