import Card from "react-bootstrap/Card";
import { Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./InfoLegna.scss";

//import prices from "../../prices.json";

const Cards = () => {
  const [prices, setPrices] = useState([{"name":"Loading...","description":"Loading...","price":"Loading...","imgpath":"Loading..."}]);
  const [isFetched, setIsFetched] = useState(false);
  useEffect(() => {
    async function fetchData() {
        await fetch('data/prices.json')
        .then(response => response.json())
      .then(data => {
        setPrices(data?.legna);
        setIsFetched(true);
      })
      .catch(error => {
        console.error(error); // Handle errors
      });
    }
    fetchData();
  }, []);

  return isFetched ? (
    <div className="infolegna__content">
      {prices.map((item, index) => {
        return (
          <Card className="infolegna__card" key={index}>
            <Card.Img
              variant="top"
              src={require(`../../media/${item.imgpath}`)}
              style={{ maxWidth: "300px"}}
              className="mx-auto"
            />
            <Card.Body>
              <Card.Title>{item.name}</Card.Title>
              <Card.Text>{item.description}</Card.Text>
              <Card.Text>A partire da €{item.price}</Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  ) : (
    <Alert variant="danger">
      Errore nel caricamento dei dati. Prova a ricaricare la pagina.
    </Alert>
  );
};

const InfoLegna = () => {
  return (
    <div className="infolegna">
      <div className="title-container">
        <h3 className="big-text tb">Legna, pellet</h3>
        <h3 className="big-text tb" style={{ textAlign: "right" }}>
          Legnetti e brick
        </h3>

        <h3 className="description">
          A palet e a sacchi. Consegna a domicilio a Trieste
        </h3>
      </div>

      <Cards />
    </div>
  );
};

export default InfoLegna;
