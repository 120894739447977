import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import { useState } from 'react';

import logo from '../../media/logo-piove.png';
import './AppBar.scss';

function AppBar(){
    const [menuState, setMenuState] = useState("menu--close");

    const toggle = () => {
        console.log("toggle");
        var css = menuState === "menu--close" ? "menu--open" : "menu--close";
        setMenuState(css);
      };


    return (
        <>
            <header className='AppBar-header'>
                <div className='AppBar-info'>
                    <div className="menu_btn">
                        <span></span>
                        <span></span>
                        <span></span>
                        <a onClick={toggle.bind(this)}></a>
                    </div>

                    <a className='AppBar-logo_btn' href="/"><img className="AppBar-logo" src={logo} alt="Header logo - piove"></img></a>
                </div>

                <Button variant="primary" href='/contact'>Contatti</Button>
                
            </header>
                <div id="menu" className={menuState}>
                    <div className="empty"></div>
                    <div className="menu__content">
                        <ul>
                            <li><a href="/" className='underline-animation'>Home</a></li>
                            <li><a href="https://www.google.com/search?hl=it-IT&gl=it&q=PIOVE+Di+Goldin+Silvio,+Via+Nazionale,+33,+34151+Trieste+TS&ludocid=16901944662537503211&lsig=AB86z5URq9LjqOqvBYeJOBy_tH1M#lrd=0x477b14b13c313e47:0xea8fc51ec7db81eb,1"
                             className='underline-animation'>Recensioni</a></li>
                            <li><a href="https://www.google.com/maps/dir//P.I.O.V.E.+Di+Goldin+Silvio/data=!4m8!4m7!1m0!1m5!1m1!1s0x477b14b13c313e47:0xea8fc51ec7db81eb!2m2!1d13.78648!2d45.68593" 
                             className='underline-animation'>Dove Siamo</a></li>
                        </ul>
                        <div className='menu__gallery'>
                            <Image src={require('../../media/gallery/collage.jpg')} fluid={false} alt='menu-collage' rounded />
                        </div>
                    </div>
                </div>
        
        </>
    );
}

/*
<nav className='AppBar-nav'>
                        <ul>
                            <li><a href='/offers'>{appBarConf[0]}</a></li>
                            <li><a>{appBarConf[1]}</a></li>
                        </ul>
                    </nav>




                    <a id="telegram_link" href='#'>

                        <FontAwesomeIcon icon={faTelegram} size="2x"/>
                    </a>
*/

// palette: https://coolors.co/palette/edede9-d6ccc2-f5ebe0-e3d5ca-d5bdaf

export default AppBar;