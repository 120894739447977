import { Outlet } from "react-router-dom";
import AppBar from '../appbar/AppBar';
import Footer from '../footer/Footer';

const Layout = () => {
    return (
        <main className="Main">
            <AppBar />
            <Outlet />
            <Footer />
        </main>
    )
}

export default Layout