import './Poster.scss';

import Fireplace from '../../media/fireplace.jpg';
import { Button } from 'react-bootstrap';

const Poster = () => {
  return (
    <div className="poster mt-3">
      <div className="poster__img scroll">
        <img src={Fireplace} alt="Fireplace"></img>
      </div>
      <div className="poster__content scroll">
        <h3 className="big-text fs">Legna e pellet di qualità</h3>
        <p className="normal-text">
          Legna e pellet a bancali e a sacchi. Consegna a domicilio a Trieste
        </p>

        <p className="normal-text">Continua a scorrere per informazioni oppure chiama al numero 040213828</p>
        <Button size='lg' href='+39040213828'>Chiama Ora</Button>
      </div>
    </div>
  );
}

export default Poster;