import { useState, useEffect } from 'react';

import './Detersivo.scss';

const Detersivo = () => {
    const [prices, setPrices] = useState([{"name":"Loading...","description":"Loading...","price":"Loading...","imgpath":"Loading..."}]);
  const [isFetched, setIsFetched] = useState(false);
  useEffect(() => {
    async function fetchData() {
        await fetch('data/prices.json')
        .then(response => response.json())
      .then(data => {
        setPrices(data);
        setIsFetched(true);
      })
      .catch(error => {
        console.error(error); // Handle errors
      });
    }
    fetchData();
  }, []);
    return (
        <div className="Detersivo">
            <div className="row-st">
                <div className='row-st__title'>
                    <h3 className='big-text tb'>E in più...</h3>
                </div>
                <img alt='detersivi sfusi immagine' src={require('../../media/gallery/detersivi-sfusi.JPG')}></img>
            </div>
            <div className="row-nd">
                <div className='row-nd__title'>

                <h3 className='big-text tb'>...Detersivi sfusi!</h3>
                <p className='normal-text'>Abbiamo aggiunto i prodotti per la pulizia professionale Quality Clean. Fra questi troviamo i detersivi sfusi, con la quale possiamo ridurre i consumi di plastica.</p>
                {
                isFetched ? <p>Prezzi da € {prices?.detersivo.price}</p>
                : null
                }
                </div>
                <img alt='detersivi sfusi immagine' src={require('../../media/gallery/detersivi-zoom.JPG')}></img>
            </div>
        </div>
    );
}

export default Detersivo;