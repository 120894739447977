import { useRef } from "react";

import Hero from "./Hero";
import Poster from "./Poster";
import InfoLegna from "./InfoLegna";
import Vino from "./Vino";
import Detersivo from "./Detersivo";



const Home = () => {
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView()
    return(
        <>
            <Hero onClick={executeScroll} />
            <Poster />
            <div ref={myRef}>
                <InfoLegna/>
            </div>
            <Vino />
            <Detersivo />
        </>
    )
}
export default Home;